:root {
	--background: #fbfbfd;
	--text: black;
	--darkdarkGray: #3a2850;
	--darkGray: #8b8b8b;
	--middleGray: #d4d4d4;
	--lightGray: #f1f2f5;
	--white: white;
	--primary: #ff7a59;
	--secondary: #0694b0;
	--secondaryLight: rgba(6, 148, 176, 0.2);
	--primaryLight: rgba(255, 122, 89, 0.2);
	--dialogBackground: white;
	--boxShadow: 0px 3px 15px rgba(0, 0, 0, 0.25);
	--lightBoxShadow: 0px 3px 7.5px rgba(0, 0, 0, 0.15);
}

body {
	width: 100vw;
	min-height: calc(100 * var(--wh));
	padding: 0px;
	margin: 0px;
	position: relative;
	font-size: 16px;
	font-weight: 500;
	overflow-x: hidden;
	background: var(--background);
	color: var(--text);
	font-family: Jost;
	-webkit-tap-highlight-color: rgba(255, 255, 255, 0);

	* {
		color: var(--text);
		transition: 0.15s background ease-in-out, 0.15s color ease-in-out, 0.15s border-color ease-in-out;
	}
	button {
		background: none;
		border: none;
		border-radius: 0px;
		cursor: pointer;
		display: flex;
		&:focus {
			outline: none;
		}
	}
	h1,
	h2,
	h3,
	h4,
	p,
	ul,
	li {
		margin: 0px;
		padding: 0px;
	}
	li {
		list-style: none;
		&::before {
			content: "\00BB";
			font-weight: bold;
			color: var(--secondary);

			margin-right: 5px;
		}
	}

	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	textarea:-webkit-autofill,
	textarea:-webkit-autofill:hover,
	textarea:-webkit-autofill:focus,
	select:-webkit-autofill,
	select:-webkit-autofill:hover,
	select:-webkit-autofill:focus {
		border: none;
		-webkit-text-fill-color: none;
		-webkit-box-shadow: 0 0 0px 1000px var(--lightGray) inset;
		box-shadow: 0 0 0px 1000px var(--lightGray) inset;
		transition: background-color 5000s ease-in-out 0s;
	}

	a {
		text-decoration: none;
	}
}
*::-webkit-scrollbar {
	display: none;
}

/**
*			Flexbox default styles
*/
.center-center {
	width: 100%;
	min-height: calc(calc(100 * var(--wh)) - 200px);
	display: flex;
	align-items: center;
	justify-content: center;
}

/**
* 			TEXT DEFAULT STYLES
*/
.text-center {
	text-align: center;
}
.bold {
	font-weight: bold;
}
.underline {
	text-decoration: underline;
}
.text-right {
	text-align: right;
}
.text-left {
	text-align: left;
}
#root .onedash-popover-wrapper {
	* {
		font-family: "Jost";
	}
	.popover {
		border-radius: 10px 10px 0px 0px;
		.content {
			margin-top: 0px;
			margin-bottom: 15px;
			.popover-title {
				font-family: "Jost";
				font-weight: bold;
				border: none;
				color: var(--primary);
				text-align: center;
			}
		}
		.popover-close {
			display: none;
		}
	}
}

.privacy-text {
	font-family: "Jost";
	font-size: 0.8rem;
	line-height: 0.8rem;
	color: var(--darkGray);

	* {
		font-family: "Jost";
	}
	a {
		color: var(--secondary);
		text-decoration: none;
	}
}

#root .submit-btn {
	text-transform: uppercase;
	font-size: 1.2rem;
}
.error,
.warning,
.info {
	background: var(--primaryLight);
	display: flex;
	align-items: center;
	border-radius: 10px;
	margin-top: 30px;
	line-height: 1.2rem;
	transition: 0.3s all linear;
	overflow: hidden;

	a {
		color: var(--primary);
		font-weight: bold;
		text-decoration: underline;
	}

	&.visible {
		max-height: 150px !important;
	}
	i {
		color: var(--primary);
		margin-left: 20px;
	}
	.text {
		margin: 20px;
		color: var(--primary);
	}
}
.info {
	background: var(--secondaryLight);
	i {
		color: var(--secondary);
	}
	.text {
		color: var(--secondary);
	}
	a {
		color: var(--primary);
	}
}

.submit-popup {
	h1 {
		text-align: center;
		font-size: 1.3rem;
		line-height: 115%;
		margin-top: -50px !important;
	}
	.wallet-link {
		margin-left: auto;
		width: 150px;
		display: block;
		margin-right: auto;
		margin-top: 20px;
		img {
			width: 100%;
		}
	}
	p {
		text-align: center;
		margin-top: 20px;
	}
	svg {
		margin-top: -30px;
	}
}
#root .onedash-popover-wrapper {
	font-weight: normal;

	h1 {
		margin-top: 20px;
	}
}
