
#root .ticket-overview
    .tickets
        display: flex
        flex-wrap: wrap
        margin: 0px -20px
        .ticket
            width: 175px
            margin: 20px

            .ticket-info, .qr,.storno
                border-radius: 10px
            .ticket-info
                background: var(--secondary)
                box-shadow: var(--boxShadow)
                display: block
                padding: 10px

                h1
                    margin-top: 0px
                    .day
                        display: block
                        font-size: 2.5rem
                        color: white
                        text-align: center
                    .month
                        display: block
                        text-align: center
                        margin-top: -10px
                        color: white
                        font-size: 1.1rem

                h2
                    font-size: 0.9rem
                    color: white
                    text-align: center
                    margin-top: 10px
                h3
                    color: white
                    text-align: center
                    font-size: 0.8rem
                    line-height: 100%
                    margin-top: 0px
                h4
                    color: white
                    text-align: center
                    font-size: 0.8rem
                    line-height: 100%
                    opacity: 0.7
                    margin-top: 20px

            .qr
                box-shadow: var(--boxShadow)
                background: white
                display: flex
                justify-content: center
                align-items: center
                padding: 20px
                margin-top: 5px

            .storno
                background: var(--primary)
                box-shadow: var(--boxShadow)
                text-transform: uppercase
                color: white
                font-weight: bold
                text-align: center
                margin-top: 10px
                padding: 10px 0px
                cursor: pointer
                &:hover
                    opacity: 0.7

    @media only screen and (max-width: 720px)
        .tickets
            justify-content: center
