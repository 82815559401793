.onedash-dialog-v2
    display: none
    font-size: 16px

    &.max-400
        .dialog-wrapper .dialog
            max-width: 400px
    &.no-padding .dialog-wrapper .dialog
        width: auto
        top: -25px
        background: none
        padding: 0
        margin: 0
        .content
            padding-right: 0px

    .dialog-wrapper
        position: fixed
        z-index: 501
        width: 100vw
        height: calc(100 * var(--wh))
        left: 0
        top: 0
        display: flex
        justify-content: center
        align-items: center

        .dialog
            background: var(--background)
            border-radius: 6px
            padding: 20px
            display: flex
            flex-direction: column
            justify-content: space-between
            position: relative
            width: calc(100% - 100px)
            max-width: 600px
            animation: scaleIn 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) forwards

        .dialog-title
            font-family: "Jost"
            font-weight: bold
            font-size: 1.6rem
            color: var(--secondary)
            border: none
            line-height: 100%
            padding-bottom: 10px
            width: calc(100% - 50px)
        .close-btn
            background: none
            border: none
            position: absolute
            right: 10px
            margin: 0px
            padding: 7.5px 12px
            top: 7.5px
            font-size: 25px
            display: inline-block
            transform: rotate(45deg)
            transition: 0.15s all ease-in-out
            cursor: pointer

            &:focus
                outline: none
            &:hover
                opacity: 0.7
                transform: rotate(0deg)

        .content
            max-height: calc((var(--wh) * 100) - 150px)
            overflow-y: auto
            padding-right: 10px

            &::-webkit-scrollbar
                width: 4px
                height: 2px

            &::-webkit-scrollbar-button
                width: 0px
                height: 0px

            &::-webkit-scrollbar-thumb
                background: #e7e7e7
                border: 0px none #ffffff
                border-radius: 50px

            &::-webkit-scrollbar-track
                background: none
                border: 0px none #ffffff
                border-radius: 50px

            &::-webkit-scrollbar-corner
                background: transparent

    .dialog-bg
        animation: fadeBGIn 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards
        position: fixed
        width: 100vw
        height: calc(100 * var(--wh))
        left: 0
        top: 0
        z-index: 500

    &.visible
        display: block

    &.is-closing
        display: block
        .dialog
            animation: scaleOut 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards
        .dialog-bg
            animation: fadeBGOut 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards

    &.prompt
        .dialog-wrapper
            z-index: 503
        .dialog-bg
            z-index: 502
        .dialog
            max-width: 400px
        .buttons
            justify-content: flex-start

    .buttons
        display: flex
        justify-content: flex-end
        &.split
            display: flex
            flex-wrap: wrap
            justify-content: space-between
            & > div
                display: flex

        button
            padding: 6px 20px
            border: none
            border-radius: 5px
            font-size: 1em
            font-weight: bold
            cursor: pointer
            transition: 0.15s all ease-in-out
            margin: 5px
            margin-right: 0px
            border: 2px solid var(--middleGray)
            &:hover
                background: var(--middleGray)
                color: white
            &:disabled
                border: 2px solid #dbdbdb
                background: #dbdbdb
                color: #a5a5a5
                pointer-events: none
            &:first-of-type
                margin-left: 0px

            &.no-style
                padding: 0px
                border-radius: 0px
                font-weight: normal
                &:hover, &:focus
                    opacity: 0.7
                &:focus
                    border: none !important
                &.danger
                    color: var(--invalid)
            &.submit:not(:disabled), &.yes:not(:disabled)
                background: var(--primary)
                color: white
                border: 2px solid var(--primary)
                &:hover
                    background: var(--primaryLight)
                    color: var(--primary)
            &.cancel:not(:disabled), &.no:not(:disabled)
                background: white
                color: black
                border: 2px solid #dbdbdb
                &:hover
                    background: #dbdbdb
            &:focus
                outline: none
                border-color: black !important

    a
        color: var(--secondary)
        text-decoration: none
        word-break: break-all

    h3
        color: var(--primary)
        text-transform: uppercase
        margin: 20px 0px 10px 0px
        font-size: 1.3rem

    h4
        color: var(--darkdarkGray)
        font-weight: bold
        margin: 10px 0px -5px 5px
        font-size: 1.1rem

    p,
    ul
        margin: 5px 0px 7.5px 5px
        font-weight: 400

        li::before
            content: "\00BB"
            font-weight: bold
            color: var(--secondary)

            margin-right: 5px

    ul
        margin: 5px 0px 7.5px 10px

    @keyframes scaleOut
        0%
            transform: scale(1)
            opacity: 1

        100%
            transform: scale(0)
            opacity: 0.1

    @keyframes scaleIn
        0%
            transform: scale(0)
            opacity: 0.1

        100%
            transform: scale(1)
            opacity: 1

    @keyframes fadeBGIn
        0%
            background: transparent
            backdrop-filter: blur(0px)
            -webkit-backdrop-filter: blur(0px)

        100%
            background: rgba(0,0,0,0.5)
            backdrop-filter: blur(4px)
            -webkit-backdrop-filter: blur(4px)

    @keyframes fadeBGOut
        0%
            background: rgba(0,0,0,0.5)
            backdrop-filter: blur(4px)
            -webkit-backdrop-filter: blur(4px)

        100%
            background: transparent
            backdrop-filter: blur(0px)
            -webkit-backdrop-filter: blur(0px)
