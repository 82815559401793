.full-page-picker
    *
        font-family: "Jost"
    .react-datepicker
        border: none
        background: none
        .react-datepicker__day-name
            font-size: 18px
            margin: 0px
            padding: 10px
            width: 23px
            line-height: 20px

        .react-datepicker__header
            padding: 20px 30px 5px 30px
            border-radius: 10px 10px 0px 0px
            background: var(--primary)
            *
                color: white

        .selected-start-date
            border-radius: 0.3rem
            background-color: var(--primary)
            color: #fff

        .react-datepicker__day
            outline: none
            border-radius: 0.3rem

            &--selected
                background-color: var(--secondary)
                color: #fff

            &--keyboard-selected:not(.selected-start-date)
                background: none
                color: black

        .day-is-disabled
            opacity: 0.3
            pointer-events: none

        .react-datepicker__month
            background: var(--dialogBackground)
            margin: 0px
            padding: 10px 30px 20px 30px
            border-radius: 0px 0px 10px 10px

        .react-datepicker__navigation
            height: 30px
            width: 30px
            border: 15px solid transparent
            right: 10px
            border-left-color: white
            top: 30px

            &.react-datepicker__navigation--previous
                border-right-color: white
                border-left-color: transparent

        .react-datepicker__day
            font-size: 22px
            margin: 0px
            padding: 10px
            width: 23px
            line-height: 25px

        .react-datepicker__current-month
            font-size: 24px
            margin: 10px
            pointer-events: none

    .confirm-date-wrapper
        max-height: 0px
        transition: 0.3s all linear
        overflow: hidden
        &.visible
            max-height: 300px

        button
            text-transform: uppercase
            font-size: 1rem

            .date
                text-transform: none
                font-size: 0.9rem
                span
                    color: white
                    opacity: 0.8
            &:hover
                color: white
                span
                    color: white !important
