.spinner-container {
	display: none;

	&.visible {
		display: flex;
		justify-content: center;
		align-items: center;

		width: 100%;
		height: 100%;

		&.full-page {
			position: fixed;
			left: 0;
			top: 0;
			z-index: 200;
			width: 100vw;
			height: calc(100 * var(--wh));
		}
	}
	&.full-page {
		cursor: wait;
		.spinner-overlay {
			position: fixed;
			z-index: 201;
			background: rgba(0, 0, 0, 0.7);
			width: 100vw;
			height: calc(100 * var(--wh));
			top: 0;
			left: 0;
			backdrop-filter: blur(4px);
			-webkit-backdrop-filter: blur(4px);
			-webkit-animation: blurIn 0.3s ease-in-out;
			animation: blurIn 0.3s ease-in-out;
		}
		.spinner {
			z-index: 202;
		}
	}
	.spinner {
		&.invisible {
			opacity: 0;
		}
		&.visible {
			opacity: 1;
		}
		transition: 0.3s all ease-in-out;
		margin: 20px auto;
		width: 40px;
		height: 40px;
		position: relative;
		transform: rotateZ(45deg);
		cursor: wait;
	}

	.sk-cube {
		float: left;
		width: 50%;
		height: 50%;
		position: relative;
		transform: scale(1.1);

		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: var(--tools-spinner-color);
			animation: sk-foldCubeAngle 2.4s infinite linear both;
			transform-origin: 100% 100%;
		}

		&.sk-cube2 {
			transform: scale(1.1) rotateZ(90deg);
			&:before {
				animation-delay: 0.3s;
			}
		}
		&.sk-cube3 {
			transform: scale(1.1) rotateZ(180deg);
			&:before {
				animation-delay: 0.6s;
			}
		}
		&.sk-cube4 {
			transform: scale(1.1) rotateZ(270deg);
			&:before {
				animation-delay: 0.9s;
			}
		}
	}

	@keyframes sk-foldCubeAngle {
		0%,
		10% {
			-webkit-transform: perspective(140px) rotateX(-180deg);
			transform: perspective(140px) rotateX(-180deg);
			opacity: 0;
		}
		25%,
		75% {
			-webkit-transform: perspective(140px) rotateX(0deg);
			transform: perspective(140px) rotateX(0deg);
			opacity: 1;
		}
		90%,
		100% {
			-webkit-transform: perspective(140px) rotateY(180deg);
			transform: perspective(140px) rotateY(180deg);
			opacity: 0;
		}
	}

	@keyframes blurIn {
		0% {
			backdrop-filter: blur(0px);
			-webkit-backdrop-filter: blur(0px);
			background: rgba(0, 0, 0, 0);
		}
		100% {
			backdrop-filter: blur(4px);
			-webkit-backdrop-filter: blur(4px);
			background: rgba(0, 0, 0, 0.5);
		}
	}
	@keyframes blurOut {
		0% {
			backdrop-filter: blur(4px);
			-webkit-backdrop-filter: blur(4px);
			background: rgba(0, 0, 0, 0.5);
		}
		100% {
			backdrop-filter: blur(0px);
			-webkit-backdrop-filter: blur(0px);
			background: rgba(0, 0, 0, 0);
		}
	}
}
