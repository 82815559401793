.header
    font-family: Raleway
    height: 40vh
    position: relative
    background-size: cover
    background-position: center
    display: flex
    flex-direction: column
    justify-content: flex-end
    padding: 30px
    margin: 0px -30px
    box-shadow: 0px 3px 30px rgba(0,0,0,0.5)
    margin-bottom: 40px

    h1
        text-transform: uppercase
        font-family: "Raleway"
        font-weight: 900
        color: #fff
        font-size: 35px
        text-shadow: 2px 2px 10px rgba(0,0,0,.5)
        line-height: 32px
        width: 70%

    h2
        color: hsla(0,0%,100%,.8)
        text-align: left
        font-weight: 700
        font-size: 17px
        margin: 10px 0 0

    .content
        z-index: 2
        position: relative
    .overlay
        background: rgba(0,0,0,0.25)
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        z-index: 1

button.primary-btn
    background: var(--primary)
    border: 2px solid var(--primary)
    color: white
    text-align: center
    font-family: "Jost"
    width: 100%
    margin: 30px 0
    border-radius: 10px
    font-weight: 700
    display: block
    font-size: 1rem
    padding: 10px 12.5px
    transition: 0.15s all ease-in-out
    cursor: pointer
    user-select: none
    &:hover
        background: none
        color: black
        .small
            color: black
            opacity: .7
    &:disabled
        pointer-events: none
        background: var(--middleGray)
        border-color: var(--middleGray)
        color: black
    .small
        display: block
        font-size: .9rem
        color: white
        opacity: .9

#root .app
    padding: 0px 30px
    padding-bottom: 60px
    & > div:not(.header)
        h1
            font-family: "Jost"
            font-weight: bold
            font-size: 1.3rem
            color: var(--secondary)
            margin-top: 50px

@media only screen and (min-width: 1025px)
    .header
        margin: 30px
        border-radius: 30px
        .overlay
            border-radius: 30px
        .content
            max-width: 900px
            width: 100%
            margin-left: auto
            margin-right: auto
@media only screen and (min-width: 720px)
    #root .app > div:not(.header)
        max-width: 900px
        width: 100%
        margin-left: auto
        margin-right: auto
